import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MenuController, PopoverController } from '@ionic/angular';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { AuthService } from '../@shared/services/credentials/auth.service';
import { UserService } from '../api/services/user.service';
import { untilDestroyed } from '../@shared/services/until-destroyed';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { SharedDataService } from '../@shared/services/shared-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.page.html',
  styleUrls: ['./shell.page.scss'],
})
export class ShellPage implements OnInit, OnDestroy {
  public logo:string = "/assets/logo.png";
  public profile:string="/assets/logo.png";
  public language: string = this.translateService.currentLang ? this.translateService.currentLang : environment.defaultLang;
  public user: any;
  public imageUrl: any;
  public isLoading= false;
  public subscription: Subscription;
  constructor(
    private menu: MenuController, 
    public popoverController: PopoverController,
    private translateService: TranslateService,
    public authService: AuthService,
    private userService : UserService,
    private sanitizer: DomSanitizer,
    private impageService: SharedDataService
    ) {
    // this.router.navigate(['/dashboard']);
    this.user = this.authService.getLoggedInUser();
   }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription =  this.impageService.currentprofileImageUrl.subscribe(imageUrl =>{
      this.imageUrl = imageUrl;
    })
    if(this.user.profileImageUrl){
      this.getprofileImage(this.user.profileImageUrl);
    }
  }

  languageChange() {   
    this.translateService.use(this.language);
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

  async presentPopover(ev?: any) {
    const popover = await this.popoverController.create({
      component: ProfileMenuComponent,
      cssClass: 'custum-menu',
      event: ev,
      translucent: true,
      showBackdrop: false,
      reference: "trigger",
      side: "bottom",
      alignment: "center"
    });
    return await popover.present();
  }

  
  getprofileImage(url: any) {
    if (this.user) {
      const profileImage$ = this.userService.getUserProfileImage(url);
      profileImage$
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
          untilDestroyed(this)
        ).subscribe(
          (response) => { 
            const objectUrl = URL.createObjectURL(response);
            const sanitizer  = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
            this.imageUrl = sanitizer;
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }
}
